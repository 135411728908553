import React from "react";
import { Image, Container } from "@atoms";

const OrganizationGrid = ({ organizations }) => {
  return (
    <Container>
      <ul className="flex flex-wrap items-center justify-center gap-12">
        {organizations.map(org => (
          <li
            key={org.uid}
            className="relative flex flex-[0_1_calc(50%-1.5rem)] items-center justify-center overflow-hidden sm:flex-[0_1_calc(33.333%-2rem)] lg:flex-[0_1_calc(20%-2.4rem)]"
          >
            <Image
              image={org.logo}
              objectFit="contain"
              ixParams={{ fit: "fill", fill: "transparent" }}
              className="max-h-20"
            />
          </li>
        ))}
      </ul>
    </Container>
  );
};

export default OrganizationGrid;
